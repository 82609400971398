import { useEffect } from "react";
import useFetch from "./useFetch";

export default function useSubscribe(plan) {
  const { fetchData, data, isFetching } = useFetch();

  useEffect(() => {
    if (!isFetching) {
      plan.actualPlan === "Master Lead"
        ? (window.location.href = data?.checkout_url)
        : (window.location.href = "https://www.toplead.tec.br/success/");
    }
  }, [isFetching]);

  const handleSubscribeForAPlan = async (userEmail, userName, userPhone) => {
    const requestData =
      plan.actualPlan === "Enterprise Lead"
        ? {
            user_email: userEmail,
            success_url: "https://www.toplead.tec.br/success/",
            cancel_url: "https://www.toplead.tec.br/cancellation/",
          }
        : {
            user_email: userEmail,
            client_name: userName,
            phone_number: userPhone,
          };
    const url = `https://quantum-pay.quant1.com.br/top_lead${
      plan.actualPlan === "Master Lead"
        ? "/create-master-lead"
        : plan.actualPlan === "Basic Lead"
        ? "/create-basic-lead"
        : "/create-enterprise-lead"
    }/`;

    await fetchData(
      url,
      "POST",
      {
        body: JSON.stringify(requestData),
      },
      { "Content-Type": "application/json" }
    );
  };

  return { handleSubscribeForAPlan };
}
