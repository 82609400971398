import VideoBackground from "./components/VideoBackground";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect } from "react";
import useStore from "./hooks/useStore";

function App() {
  const navbarLinks = useStore((state) => state.navbarLinks);
  const setActiveSection = useStore(
    (state) => state.setActiveSection
  );

  useEffect(() => {
    if (setActiveSection && navbarLinks && navbarLinks.length > 0) {
      setActiveSection(navbarLinks[0].title);
    }
  }, []);

  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      <VideoBackground />
    </div>
  );
}

export default App;
