import { useEffect } from "react";
import { HiCheckBadge } from "react-icons/hi2";
import "./index.css";
import { useTranslation } from "react-i18next";

const SuccessSubscribe = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("route.subscribe.success.pageTitle");
  }, []);

  return (
    <div className="success-subscribe page">
      <main>
        <HiCheckBadge size={80} style={{ color: "var(--green-color)" }} />
        <div className="message">
          <h2>{t("route.subscribe.success.heading")}</h2>
          <p>{t("route.subscribe.success.description")}</p>
        </div>
      </main>
    </div>
  );
};

export default SuccessSubscribe;
