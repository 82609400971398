import { useEffect, useState } from "react";

export default function useFetch(url, type, config) {
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async (requestUrl, requestType, requestConfig, headers) => {
    try {
      await fetch(requestUrl, {
        method: requestType,
        headers: headers,
        ...requestConfig,
      })
        .then((data) => data.json())
        .then((jsonData) => setData(jsonData));
        
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    url && type && fetchData(url, type, config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, isFetching, fetchData };
}
