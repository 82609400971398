import { useEffect } from "react";
import "./index.css";
import useStore from "../../hooks/useStore";
import { useTranslation } from "react-i18next";

const Home = () => {
  const navbarLinks = useStore((state) => state.navbarLinks);
  const activeSection = useStore((state) => state.activeSection);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("route.home.pageTitle");
  }, []);

  return (
    <div className="home page">
      <main>
        {navbarLinks.map((item) => {
          if (item.title === activeSection) {
            return item.component;
          } else {
            return null;
          }
        })}
      </main>
    </div>
  );
};

export default Home;
