import { LuCheck } from "react-icons/lu";
import { LuX } from "react-icons/lu";
import "./index.css";
import ButtonLinkPage from "../ButtonLinkPage";
import Translator from "../Translator";

const PlanCard = ({ title, description, price, benefits }) => {
  return (
    <>
      <div id="plan-card">
        <div className="top-card">
          <h2>
            <strong style={{ color: "var(--secondary-color)" }}>{title}</strong>
          </h2>
        </div>
        {price === 0.0 ? (
          title === "Enterprise Lead" ? (
            <h2>{<Translator path="planCard.contact" />}</h2>
          ) : (
            <h2>{<Translator path="planCard.free" />}</h2>
          )
        ) : (
          <h2>
            {
              <Translator
                path="planCard.price"
                interpolation={{ price: price }}
              />
            }{" "}
            <span style={{ fontSize: "12px" }}>
              {<Translator path="planCard.month" />}
            </span>
          </h2>
        )}
        <p>{description}</p>
        <ul className="benefits">
          {benefits.available &&
            benefits.available.map((item, index) => {
              return (
                <li key={index}>
                  <LuCheck color="var(--green-color)" />
                  {item.title}
                </li>
              );
            })}
          {benefits.unavailable &&
            benefits.unavailable.map((item, index) => {
              return (
                <li key={index} style={{ color: "hsla(240, 19%, 15%, 0.502)" }}>
                  <LuX color="var(--red-color)" />
                  {item.title}
                </li>
              );
            })}
        </ul>
        <ButtonLinkPage
          title={<Translator path="planCard.button" />}
          route={`/subscribe/${title}`}
          linkStyle={{
            width: "100%",
            height: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        />
      </div>
    </>
  );
};

export default PlanCard;
