import ButtonLinkPage from "../../components/ButtonLinkPage";
import "./index.css";
import { useRouteError } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const error = useRouteError();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("route.error.pageTitle");
  }, []);

  return (
    <div className="error page">
      <div className="error-message">
        <h1>{error.status}</h1>
        <h2>{error.statusText}</h2>
      </div>
      <div className="return-container">
        <ButtonLinkPage
          title={t("route.error.button")}
          route={`/`}
          linkStyle={{
            width: "100%",
            height: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
