import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../hooks/useStore";
import Translator from "../Translator";
import LanguageDropdown from "../LanguageDropdown";

const Header = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const activeSection = useStore((state) => state.activeSection);

  const setActiveSection = useStore((state) => state.setActiveSection);

  const navbarLinks = useStore((state) => state.navbarLinks);

  return (
    <header>
      <div className="logo">
        <a href="/">
          <img src="/images/top-lead-logo.png" alt="Top Lead logo" />
        </a>
      </div>
      <div className="navbar">
        <LanguageDropdown />
        {location.pathname === "/" ? (
          navbarLinks.map((option) => (
            <p
              style={{
                color: activeSection === option.title && "var(--primary-color)",
              }}
              id="header-nav-option"
              key={option.id}
              onClick={() => setActiveSection(option.title)}
            >
              {option.title}
            </p>
          ))
        ) : (
          <p id="header-nav-option" onClick={() => navigate("/")}>
            {<Translator path="header.back" />}
          </p>
        )}
      </div>
    </header>
  );
};

export default Header;
