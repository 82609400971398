import useSubscribe from "../../hooks/useSubscribe";
import TextInput from "../TextInput";
import SubmitInput from "../SubmitInput";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Translator from "../Translator";

const regexNumber = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);

const requiredMessage = <Translator path="input.required" />;

const createSubscribeFormSchema = z.object({
  name: z.string().min(3, <Translator path="input.email.validationMessage" />),
  email: z
    .string()
    .email(<Translator path="input.email.validationMessage" />)
    .min(1, requiredMessage),
  phone: z
    .string()
    .regex(regexNumber, <Translator path="input.phone.validationMessage" />)
    .min(1, requiredMessage),
});

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(createSubscribeFormSchema),
  });

  const { handleSubscribeForAPlan } = useSubscribe("Enterprise Lead");

  return (
    <form
      onSubmit={handleSubmit((data, event) => {
        event.preventDefault();
        handleSubscribeForAPlan(data.email, data.name, data.phone);
      })}
    >
      <TextInput
        label={<Translator path="input.name.label" />}
        type="text"
        placeholder={<Translator path="input.name.placeholder" />}
        validationErrorState={errors.name}
        {...register("name")}
      />
      <TextInput
        label={<Translator path="input.email.label" />}
        type="email"
        placeholder={<Translator path="input.email.placeholder" />}
        validationErrorState={errors.email}
        {...register("email")}
      />
      <TextInput
        label={<Translator path="input.phone.label" />}
        type="phone"
        placeholder={<Translator path="input.phone.placeholder" />}
        validationErrorState={errors.phone}
        {...register("phone")}
      />
      <SubmitInput
        value={<Translator path="input.submit.value" />}
        validationErrorState={errors.email || errors.phone}
      />
    </form>
  );
};

export default ContactForm;
