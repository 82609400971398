import { useTranslation } from "react-i18next";
import "./index.css";

const VideoBackground = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="overlay"></div>
      <div className="video-background">
        <video autoPlay muted loop>
          <source src="/videos/aurora-background.mp4" type="video/mp4" />
          {t("fallbackVideoMessage")}
        </video>
        <img
          src="/images/aurora-fallback-background.png"
          alt="imagem de fallback"
        />
      </div>
    </>
  );
};

export default VideoBackground;
