import App from "../App";
import Home from "./Home";
import { createBrowserRouter } from "react-router-dom";
import Subscribe from "./Subscribe";
import ErrorPage from "./ErrorPage";
import SuccessSubscribe from "./Subscribe/Success";
import CancelSubscribe from "./Subscribe/Cancel";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/subscribe/:plan",
        element: <Subscribe />,
      },
    ],
  },
  {
    path: "/success",
    element: <SuccessSubscribe />,
    errorElement: <ErrorPage />
  },
  {
    path: "/cancellation",
    element: <CancelSubscribe />,
    errorElement: <ErrorPage />
  }
]);

export default router;
