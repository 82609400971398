import { useTranslation } from "react-i18next";
import { generateUniqueId } from "../../functions/generateUniqueId";
import { FaAngleDown } from "react-icons/fa";
import "./index.css";
import { useState } from "react";

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const handleOpenDropdown = () => {
    setIsOpen((prevState) => (prevState === true ? false : true));
  };

  return (
    <div className="dropdown" onClick={() => handleOpenDropdown()}>
      <div className="dropdown-button">
        <p id="header-nav-option">{currentLanguage}</p>
        <FaAngleDown size={25} />
      </div>
      <ul className={`dropdown-menu${isOpen ? " open" : ""}`}>
        {i18n.languages.map(
          (language) =>
          !(language === currentLanguage || language === 'pt') && (
              <div
                id="header-nav-option"
                key={generateUniqueId()}
                onClick={() => i18n.changeLanguage(language)}
              >
                {language}
              </div>
            )
        )}
      </ul>
    </div>
  );
};

export default LanguageDropdown;
