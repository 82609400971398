import Translator from "../Translator";

const Footer = () => {
  const currentDate = Date.now();
  const date = new Date(currentDate);

  return (
    <footer>
      <div>
        {
          <Translator
            path={"footer.copyright"}
            interpolation={{ year: date.getFullYear() }}
          />
        }
      </div>
      <div>
        {<Translator path={"footer.powered"} />}{" "}
        <a href="https://www.quant1.com.br" target="_blank" rel="noreferrer">
          <strong>Quantum Tecnologia</strong>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
