import { create } from "zustand";
import { generateUniqueId } from "../functions/generateUniqueId";
import { FaChartPie } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaTachometerAlt } from "react-icons/fa";
import { SiTwilio } from "react-icons/si";
import { FaChartLine } from "react-icons/fa";
import { MdOutlineWeb } from "react-icons/md";
import { FaRegCheckSquare } from "react-icons/fa";
import { MdOutlineGpsFixed } from "react-icons/md";
import { MdDataObject } from "react-icons/md";

import HomeSection from "../components/HomeSection";
import PlansSection from "../components/PlansSection";

import Translator from "../components/Translator";

const useStore = create((set) => ({
  plans: [
    {
      id: generateUniqueId(),
      plan_title: "Basic Lead",
      description: <Translator path="useStore.plan.basic.description" />,
      price: 0.0,
      benefits: {
        available: [],
        unavailable: [
          {
            title: <Translator path="useStore.plan.benefits.stages" />,
            icon: <FaTachometerAlt />,
          },
          {
            title: <Translator path="useStore.plan.benefits.reports" />,
            icon: <FaChartLine />,
          },
          {
            title: <Translator path="useStore.plan.benefits.campaigns" />,
            icon: <FaRegClock />,
          },
          {
            title: <Translator path="useStore.plan.benefits.segments" />,
            icon: <FaChartPie />,
          },
          {
            title: <Translator path="useStore.plan.benefits.landingPages" />,
            icon: <MdOutlineWeb />,
          },
          {
            title: <Translator path="useStore.plan.benefits.forms" />,
            icon: <FaRegCheckSquare />,
          },
          {
            title: <Translator path="useStore.plan.benefits.geoTracking" />,
            icon: <MdOutlineGpsFixed />,
          },
          {
            title: (
              <Translator path="useStore.plan.benefits.whatsAppIntegrationWithTwillio" />
            ),
            icon: <SiTwilio />,
          },
          { title: "API", icon: <MdDataObject /> },
        ],
      },
    },
    {
      id: generateUniqueId(),
      plan_title: "Master Lead",
      description: <Translator path="useStore.plan.master.description" />,
      price: 14.99,
      benefits: {
        available: [
          {
            title: <Translator path="useStore.plan.benefits.stages" />,
            icon: <FaTachometerAlt />,
          },
          {
            title: <Translator path="useStore.plan.benefits.reports" />,
            icon: <FaChartLine />,
          },
          {
            title: <Translator path="useStore.plan.benefits.campaigns" />,
            icon: <FaRegClock />,
          },
          {
            title: <Translator path="useStore.plan.benefits.segments" />,
            icon: <FaChartPie />,
          },
          {
            title: <Translator path="useStore.plan.benefits.landingPages" />,
            icon: <MdOutlineWeb />,
          },
          {
            title: <Translator path="useStore.plan.benefits.forms" />,
            icon: <FaRegCheckSquare />,
          },
          {
            title: <Translator path="useStore.plan.benefits.geoTracking" />,
            icon: <MdOutlineGpsFixed />,
          },
          {
            title: (
              <Translator path="useStore.plan.benefits.whatsAppIntegrationWithTwillio" />
            ),
            icon: <SiTwilio />,
          },
        ],
        unavailable: [{ title: "API", icon: <MdDataObject /> }],
      },
    },
    {
      id: generateUniqueId(),
      plan_title: "Enterprise Lead",
      description: <Translator path="useStore.plan.enterprise.description" />,
      price: 0.0,
      benefits: {
        available: [
          {
            title: <Translator path="useStore.plan.benefits.stages" />,
            icon: <FaTachometerAlt />,
          },
          {
            title: <Translator path="useStore.plan.benefits.reports" />,
            icon: <FaChartLine />,
          },
          {
            title: <Translator path="useStore.plan.benefits.campaigns" />,
            icon: <FaRegClock />,
          },
          {
            title: <Translator path="useStore.plan.benefits.segments" />,
            icon: <FaChartPie />,
          },
          {
            title: <Translator path="useStore.plan.benefits.landingPages" />,
            icon: <MdOutlineWeb />,
          },
          {
            title: <Translator path="useStore.plan.benefits.forms" />,
            icon: <FaRegCheckSquare />,
          },
          {
            title: <Translator path="useStore.plan.benefits.geoTracking" />,
            icon: <MdOutlineGpsFixed />,
          },
          {
            title: (
              <Translator path="useStore.plan.benefits.whatsAppIntegrationWithTwillio" />
            ),
            icon: <SiTwilio />,
          },
          { title: "API", icon: <MdDataObject /> },
        ],
        unavailable: [],
      },
    },
  ],
  navbarLinks: [
    {
      id: generateUniqueId(),
      title: <Translator path="useStore.navbarLinks.home" />,
      component: <HomeSection key={generateUniqueId()} />,
    },
    {
      id: generateUniqueId(),
      title: <Translator path="useStore.navbarLinks.plans" />,
      component: <PlansSection key={generateUniqueId()} />,
    },
  ],
  homeContents: [
    {
      id: generateUniqueId(),
      title: <Translator path="useStore.homeContent.content1.title" />,
      description: (
        <Translator path="useStore.homeContent.content1.description" />
      ),
    },
    {
      id: generateUniqueId(),
      title: <Translator path="useStore.homeContent.content2.title" />,
      description: (
        <Translator path="useStore.homeContent.content2.description" />
      ),
    },
    {
      id: generateUniqueId(),
      title: <Translator path="useStore.homeContent.content3.title" />,
      description: (
        <Translator path="useStore.homeContent.content3.description" />
      ),
    },
  ],
  activeSection: null,

  setActiveSection: (activeSection) =>
    set(() => ({ activeSection: activeSection })),
}));

export default useStore;
