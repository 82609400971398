import React from "react";
import PlanCard from "../PlanCard";
import "./index.css";
import useStore from "../../hooks/useStore";

const PlansSection = () => {
  const plans = useStore((state) => state.plans);
  
  return (
    <section className="plans-section">
      <div className="plans">
        {plans.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <PlanCard
                title={item.plan_title}
                description={item.description}
                price={item.price}
                benefits={item.benefits}
              />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default PlansSection;
