import React from "react";
import "./index.css";

const TextInput = React.forwardRef((props, ref) => {
  const { label, type, placeholder, validationErrorState, ...rest } = props;

  return (
    <>
      <label htmlFor="">
        {label}
        <input
          type={type}
          placeholder={placeholder}
          ref={ref}
          {...rest}
          className={validationErrorState ? "invalid" : ""}
        />
        {validationErrorState && (
          <span style={{ color: "var(--red-color)" }}>{validationErrorState.message}</span>
        )}
      </label>
    </>
  );
});

export default TextInput;
