import { useParams, useNavigate } from "react-router-dom";
import "./index.css";
import { useEffect, useState } from "react";
import SubscribeForm from "../../components/SubscribeForm";
import ContactForm from "../../components/ContactForm";
import useStore from "../../hooks/useStore";
import { useTranslation } from "react-i18next";

const Subscribe = () => {
  const { plan } = useParams();

  const plans = useStore((state) => state.plans);

  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    setSelectedPlan(plans.find((item) => plan === item.plan_title));
  }, [plan, plans, selectedPlan]);

  useEffect(() => {
    if (selectedPlan === undefined) {
      navigate("/");
    }
  }, [navigate, selectedPlan]);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("route.subscribe.pageTitle");
  }, []);

  return (
    <>
      {selectedPlan && (
        <div className="subscribe page">
          <div className="form">
            <div className="subscribe-title">
              <h2>{t("route.subscribe.form.title")}</h2>
              {selectedPlan.price === 0.0 ? (
                plan === "Enterprise Lead" ? (
                  <p>{t("route.subscribe.form.subTitle.enterprise")}</p>
                ) : (
                  <p>
                    {t("route.subscribe.form.subTitle.basic", {
                      plan: plan,
                    })}
                  </p>
                )
              ) : (
                <p>
                  {t("route.subscribe.form.subTitle.master", {
                    plan: plan,
                    price: selectedPlan.price,
                  })}
                </p>
              )}
            </div>
            {plan === "Enterprise Lead" ? (
              <ContactForm />
            ) : (
              <SubscribeForm actualPlan={plan} />
            )}
          </div>
          <div className="plan-details">
            <div>
              <h1>{plan}</h1>
              <p>{selectedPlan.description}</p>
            </div>
            <ul className="benefits">
              {selectedPlan.benefits.available.length !== 0 ? (
                selectedPlan.benefits.available.map((item, index) => {
                  return (
                    <li key={index}>
                      {item.icon}
                      {item.title}
                    </li>
                  );
                })
              ) : (
                <p>
                  {t("route.subscribe.noBenefits", {
                    plan: plan,
                  })}
                </p>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscribe;
