import useSubscribe from "../../hooks/useSubscribe";
import TextInput from "../TextInput";
import SubmitInput from "../SubmitInput";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import Translator from "../Translator";

const createSubscribeFormSchema = z.object({
  email: z
    .string()
    .email(<Translator path="input.email.validationMessage" />)
    .min(1, <Translator path="input.required" />),
});

const SubscribeForm = (actualPlan) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(createSubscribeFormSchema),
  });

  const { handleSubscribeForAPlan } = useSubscribe(actualPlan);

  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit((data, event) => {
        event.preventDefault();
        handleSubscribeForAPlan(data.email);
      })}
    >
      <TextInput
        label={t("input.email.label")}
        type="email"
        placeholder={t("input.email.placeholder")}
        validationErrorState={errors.email}
        {...register("email")}
      />
      <SubmitInput
        value={t("input.submit.value")}
        validationErrorState={errors.email}
      />
    </form>
  );
};

export default SubscribeForm;
