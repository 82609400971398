import React from "react";
import "./index.css";

const SubmitInput = React.forwardRef((props, ref) => {
  const { value, validationErrorState, ...rest } = props;
  return (
    <>
      <input
        type="submit"
        value={value}
        ref={ref}
        disabled={validationErrorState ? true : false}
        {...rest}
      />
    </>
  );
});

export default SubmitInput;
