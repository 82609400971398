import { Link } from "react-router-dom";

const ButtonLinkPage = ({ title, route, linkStyle, buttonStyle }) => {
    return (
        <Link
            to={route}
            style={linkStyle}
        >
            <button style={buttonStyle}>{title}</button>
        </Link >
    )
}

export default ButtonLinkPage;