import HomeNavOption from "../HomeNavOption";
import { LuArrowRight } from "react-icons/lu";
import React from "react";
import "./index.css";
import useStore from "../../hooks/useStore";
import Translator from "../Translator";

const HomeSection = () => {
  const homeContents = useStore((state) => state.homeContents);

  return (
    <section className="home-section">
      <div className="heading">
        <h1>
          {<Translator path="homeSection.heading" />}{" "}
          <strong>{<Translator path="global.digitalMarketing" />}</strong>
        </h1>
      </div>
      <div className="home-nav">
        {homeContents.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <HomeNavOption
                title={item.title}
                description={item.description}
                link={item.link}
                icon={<LuArrowRight size={20} />}
              />
              {index !== homeContents.length - 1 && <div id="divider" />}
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default HomeSection;
